import "./Latest-blogs.scss";
import education from "../../assets/latestBlogs/education.jpg";
import celebrationsForGood from "../../assets/latestBlogs/celebrationsForGood.jpg"
import skillDevelopmentProgram from "../../assets/latestBlogs/skillDevelopmentProgram.jpg"

function LatestBlogs() {
  const LatestBlogsLists = [
    {
      id: 1,
      img: education,
      location: "Education",
      caption:
        "Be the Change Campaign",
      description:
        "Make a Difference Every Month: Your Monthly Donations Empower Underprivileged and Differently Abled Students for their education",
      link: "https://www.youtube.com/playlist?list=PLSyvORf0_bqz6akgzlLGiCOaFWo3Vxuit",
    },
    {
      id: 2,
      img: celebrationsForGood,
      location: "Celebrations for Good",
      caption:
        "Celebrate with the Uncelebrated",
      description:
        "Turn Your Celebrations into Acts of Goodness: Partner with Us on Your Special Day to Empower Differently Abled Sports, Tree Planting, and More!",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeY1vy7FJqanclImARyLXVHJD0U0LnLBT_k_I0vRzr6vdZpPA/viewform?usp=sf_link",
    },
    {
      id: 3,
      img: skillDevelopmentProgram,
      location: "Skill development program",
      caption:
        "Skill development program",
      description:
        "Our program aims to empower young minds in orphanages through skill development, nurturing a generation of knowledge seekers, and dream achievers inspired by Dr. APJ Abdul Kalam's vision.",
      link: "javascript:void(0)",
    },
  ];
  return (
    <>
      <section className="latest-blogs" id='projects'>
        <div className="container">
          <div className="mb-6">
            <h2 className="latest-blogs__heading font-bold">Our Projects</h2>
          </div>
          <div>
          <div className="latest-blogs__items__holder">
            {LatestBlogsLists.map((item) => (
              <div className="latest-blogs__item p-4" key={item.id}>
              <a href={item.link}>
                <div className="mb-6">
                  <img className="latest-blogs__item-img" src={item.img} alt={item.caption} loading="lazy"/>
                </div>
                <p className="latest-blogs__item-text-small mb-6">
                 {item.location}
                </p>
                <h6 className="mb-6 font-bold latest-blogs__item-text-bold">
                 {item.caption}
                </h6>
                <p className="mb-6 latest-blogs__item-text-small">
                 {item.description}
                </p>
                </a>
              </div>
            ))}
          </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LatestBlogs;
