import "./Header.scss"
import logo from "../../../assets/logo.png"
import web from "../../../assets/banner/web.png"
import mobile from "../../../assets/banner/mobile.png"
import { useEffect } from "react"
import { useState } from "react"
import Payment from "../../payment/Payment"

function Header() {
    const navItems = [
        {
            name: "Home",
            link: "#home"
        },
        {
            name: "Our Mission",
            link: "#mission"
        },
        {
            name: "Our Projects",
            link: "#projects"
        },
        {
            name: "Contact",
            link: "#contact"
        }
    ]; 
    const canShowBanner = true;
    const [isMenuOpen, setisMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showPaymentQr, setPaymentQr] = useState(false);
    const [showBanner, setBanner] = useState(canShowBanner);
    const cssClass = `fixed-header ${scrollPosition > 0 || isMenuOpen ? 'fixed-header-bg' : ''}`;
    const hamburgerMenuItem  = {
        color: scrollPosition > 0 || isMenuOpen ? '#1C235D' : '#ffffff'
    }
    const toggelMenu = () => {
        setisMenuOpen(!isMenuOpen);
    }
    const openPaymentQr = () => {
        setBanner(false);
        setPaymentQr(true);
    }
    const isPaymentQrClose = (value) => {
        setPaymentQr(!value)
    }

    const closeBanner = (value) => {
        setBanner(!value)
    }

  useEffect(() => {
    if (typeof window !== "undefined") {
      
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      }

      window.addEventListener("scroll", handleScroll);
   
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [])
    
    return <>
    <div className={cssClass}>
    <div className="header py-4 container">
            <div className="flex header-logo-center">
                <div className="hidden hamburger-menu flex-col-reverse"  onClick={toggelMenu}>
                <i className={`menu-icon fa ${isMenuOpen ? 'fa-times' : 'fa-bars'}`} aria-hidden="true" style={hamburgerMenuItem}></i>
                </div>
                <div className="flex justify-between items-center">
                    <img src={logo} alt="logo" className="nav-logo" loading="lazy"></img>
                    <p className="pl-2.5"><strong>ISC</strong> Foundation</p>
                </div>
                <div className="mobi-donate-btn">
                     <button className="primary-btn" onClick={openPaymentQr}>Donate</button>
                </div>
            </div>
            <nav className={`nav-bar__holder ${isMenuOpen ? 'active' : ''}`}>
                <ul className="nav-list flex">
                    {navItems.map(item => (
                        <li key={item.name} className="py-2 nav-item cursor-pointer" onClick={toggelMenu}>
                            <a href={item.link}>{item.name}</a>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="desk-donate-btn">
                <button className="primary-btn" onClick={openPaymentQr}>Donate</button>
            </div>
        </div>
    </div>
    {showPaymentQr && <div className="payment-model-holder">
                <div className="blur-bg"></div>
                <Payment closePaymentQrValue={isPaymentQrClose}></Payment>
            </div>}

    {showBanner && <div className="payment-model-holder">
        <div className="blur-bg"></div>
        <div className="banner-model">
            <div className="banner-container">
                <i class="fa fa-times banner-close" onClick={closeBanner}></i>
                <div className="banner-mobile">
                <img src={mobile} alt="banner image"/>
                </div>
                
                <div  className="banner-web">
                <img src={web} alt="banner image"/>
                </div>
                
            </div>
        </div>
    </div>}
    </>
}
export default Header