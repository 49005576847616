import "./Payment.scss"
import { QRCode } from 'react-qrcode-logo';
import BhimLogoVector from "../../assets/payment/BhimLogoVector.png";
import UpiColor from "../../assets/payment/UpiColor.png";
import googlePayLogo from "../../assets/payment/googlePayLogo.png";
import paytmLogo from "../../assets/payment/paytmLogo.png";
import phonepeLogo from "../../assets/payment/phonepeLogo.png";
import logo from "../../assets/logo.png"; 
import { useState } from "react"

function Payment (props){
  const [showCopy, setShowCopy] = useState(false);
  const closePaymentQr = () => {
    props.closePaymentQrValue(true);
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText('QR919578363702-3131@unionbankofindia');
    setShowCopy(true);
  }


  
    return <>
    <div className='payment-model'>
      <div className="payment-model__header">
        <img src={logo} alt="logo" loading="lazy"/>
        <p>ISC Foundation</p>
      </div>
      <h2>Donate now<span>!</span></h2>
      <div className="qr-holder">
        <QRCode
        className="qr-code"
        value={`upi://pay?pa=QR919578363702-3131@unionbankofindia&pn=ISC Foundation`}
        size="300"
        // logoImage=""
        // logoWidth="80"
        // logoHeight="100"
        // logoOpacity="0.6"
      />
        </div>
        <p className="upi-header text-center">UPI ID</p>
        <div className="payment__upi-id text-center copy">
          <span>QR919578363702-3131@unionbankofindia </span>  
          <i className="fa fa-clone copy-icon" onClick={copyToClipboard}  aria-hidden="true"></i>
          {showCopy && <p className="copy-text">Copied</p>}
        </div>
        <div className="upi-apps">
          <img src={BhimLogoVector} alt="logo" loading="lazy"/>
          <img src={UpiColor} alt="logo" loading="lazy"/>
          <img src={googlePayLogo} alt="logo" loading="lazy"/>
          <img src={paytmLogo} alt="logo" loading="lazy"/>
          <img src={phonepeLogo} alt="logo" loading="lazy"/>
        </div>
        <div className="payment-model__close">
          <button onClick={closePaymentQr}>Close</button>
        </div>
    </div>
    </>
}
export default Payment