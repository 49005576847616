import "./Footer.scss";
import logo from "../../assets/logo.png"

function Footer() {
  const socialMedia = [
    {
        id: 1,
        icon:'fa fa-instagram',
        name:'Instagram',
        link:'https://www.instagram.com/isc_foundation_/'
    },
    {
        id: 2,
        icon:'fa fa-youtube-play',
        name:'YouTube',
        link:'https://www.youtube.com/@isc_foundation/featured'
    },
    {
        id: 3,
        icon:'fa fa-linkedin',
        name:'LinkedIn',
        link:'https://www.linkedin.com/company/isc-media-cum-foundation/?trk=public_profile_experience-item_profile-section-card_image-click&originalSubdomain=in'
    }
]
  return (
    <>
      <section className="footer" id="contact">
        <div className="container">
          <div className="about-contact flex footer-border">
          <article className="about mx-5">
          <div className="about-header flex items-center	">
              <img src={logo} alt="isc foundation" loading="lazy"/>
              <h3>ISC Foundation</h3>
            </div>
            <p className="about-desc">
              At ISC Foundation, we are a dedicated group of young individuals on a mission to transform the lives of the underprivileged and marginalized communities. We believe in the power of youth to create meaningful change and are committed to making a positive impact.
            </p>
          </article>
          <div className="contact mx-5">
            <table>
              <caption>Get In Touch</caption>
              <tr>
                <td  className="contact-icon"><i class="fa fa-home" aria-hidden="true"></i></td>
                <td>Chennai, TamilNadu, India</td>
              </tr>
              <tr>
                <td  className="contact-icon"><i class="fa fa-envelope" aria-hidden="true"></i></td>
                <td> <a href="mailto:iscmediacumfoundation@gmail.com">iscmediacumfoundation@gmail.com</a></td>
              </tr>
              <tr>
                <td  className="contact-icon"><i class="fa fa-phone" aria-hidden="true"></i></td>
                <td><a href="tel:91+9566613702">91+9566613702</a></td>
              </tr>
              <tr>
                <td  className="contact-icon"><i class="fa fa-whatsapp" aria-hidden="true"></i></td>
                <td><a href="https://wa.me/919566613702"> Whatsapp(9566613702)</a></td>
              </tr>
            </table>
          </div>
          </div>
          <div className="network text-center footer-border">
            <div className="network-holder">
            {socialMedia.map(item => (
                <div className="network-item">
                    <a href={item.link} target="_blank">
                        <i className={item.icon} aria-hidden="true">
                        </i>
                    </a>
                    
                </div>
            ))}
            </div>
          </div>
          <div className="copy-right text-center">
            <p className="mb-2">© Copyright-2023 ISC Foundation</p>
            <p>All Rights Reserved</p>
          </div>
        </div>
      </section>
    </>
  );
}
export default Footer;
