import "./App.scss";
import Footer from "./application/footer/Footer";
import HelpUs from "./application/helpus/HelpUs";
import Hero from "./application/hero/Hero";
import LatestBlogs from "./application/latest-blogs/Latest-blogs";
import Mission from "./application/mission/Mission";
import Projects from "./application/projects/Projects";
import Social from "./application/social/Social";
function App() {
  return (
    <div className="app">
      <Hero></Hero>
      <Mission></Mission>
      {/* <Projects></Projects> */}
      <HelpUs></HelpUs>
      <LatestBlogs></LatestBlogs>
      <Footer></Footer>
      {/* <Social></Social> */}
    </div>
  );
}

export default App;
