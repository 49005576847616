import { useState } from "react"
import Payment from "../payment/Payment"
import "./HelpUs.scss"

function HelpUs() {
    const [showPaymentQr, setPaymentQr] = useState(false);
    const openPaymentQr = () => {
        setPaymentQr(true);
    }
    const isPaymentQrClose = (value) => {
        setPaymentQr(!value)
    }
    const becomeAMonthlyDonor = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdYNsBUDWLPrvpvMXTGMY-9tk-Kns89727MjGamRw9QBy1Djg/viewform', '_blank')
    }
    const joinUsAsAVolunteer = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLScQ1OE9ORsurqfen62kGy1KvqSOB6mRtSIo_HXjJRo9f3JCqg/viewform', '_blank')
    }
    return <>
        <div className="help-us-mobi">
            <div className="helpus__text">
                <div className="container text-center">
                <h1 className="helpus__heading font-bold text-center">Join Our Cause: Make a Difference!</h1>
                <p className="helpus__caption">At ISC Foundation, we firmly believe that collective efforts can bring about meaningful change in the world. Your support can be the catalyst for transforming lives and communities.</p>
                <div className="flex justify-around helpus__caption__action">
                    {/* {isMobile && <button className="primary-btn mobi-donate">
                        <a href="upi://pay?pa=776902010003131@ubin0577693.ifsc.npci&pn=ISC Foundation">Donate</a>
                    </button>} */}
                    {/* <button className="primary-btn mobi-donate" onClick={openPaymentQr}>Donate</button> */}
                    <button className="primary-btn mobi-donate become-a-monthly-donor" onClick={becomeAMonthlyDonor}> Become a Monthly Donor</button>
                    <button className="primary-btn__outline" onClick={joinUsAsAVolunteer}>Join us as a volunteer</button>
                </div>
                </div>
            </div>
            {showPaymentQr && <div className="payment-model-holder">
                <div className="blur-bg"></div>
                <Payment closePaymentQrValue={isPaymentQrClose}></Payment>
            </div>}
            <div className="helpus__parallax"></div>
        </div>
    </>
}
export default HelpUs