import herobg_thumnail from "../../assets/hero/herobg_thumnail.jpeg"
import "./Hero.scss";
import Header from "../component/header/Header";
import Button from "../component/button/Button";
import herobg from "../../assets/hero/herobg.mp4"
function Hero() {
    const beOneOfUs = (e) => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLScQ1OE9ORsurqfen62kGy1KvqSOB6mRtSIo_HXjJRo9f3JCqg/viewform', '_blank');
      }        
    return <>
        <section className="hero" id="home">
        <video autoplay="true" muted loop poster={herobg_thumnail} class="background-video">
            <source src={herobg} type="video/mp4"></source>
        </video>
            <Header></Header>
            <div className="container">
                <div className="hero-section absolute">
                    <div className="hero-section__text">
                        <h1 className="hero-heading typewriter">
                        <p>Uniting for a Brighter Tomorrow: Join Us Today in Creating a</p> <span className="inline-block">Better Future&nbsp;</span>
                        </h1>
                        <p className="hero-desc">
                        "Empowering Communities, Inspiring Change: ISC Foundation - Building a Brighter Future Together"
                        </p>
                        <div>
                            <div className="hero-btn__holder">
                                <div className="inline-block" onClick={beOneOfUs}><Button text="Be One Of Us!"></Button></div>
                                {/* <button className="hero-btn"><span>Watch our Videos</span></button> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="hero-section__img">
                        <img src={logo} alt="logo" className="hero__img img1"></img>
                        <img src={logo} alt="logo" className="hero__img img2"></img>
                        <img src={logo} alt="logo" className="hero__img img3"></img>
                    </div> */}
                </div>
            </div>
        </section>
    </>
}

export default Hero