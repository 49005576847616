import "./Mission.scss";

function Mission() {
    const missionList = [
        {
            id: 1,
            icon: "fa fa-graduation-cap",
            header: "Education",
            description: "Empowering Communities, Inspiring Change: ISC Foundation - Building a Brighter Future Together"
        },
        {
            id: 2,
            icon: "fa fa-child",
            header: "Empowering Orphaned Youth",
            description: "Equipping orphanage children with essential soft skills for a brighter future"
        },
        {
            id: 3,
            icon: "fa fa-tree",
            header: "Green Initiative",
            description: "Promoting tree plantation for a sustainable and greener future."
        },
        {
            id: 4,
            icon: "fa fa-heartbeat",
            header: "Life Saviors",
            description: "To inspire and mobilize individuals to become blood donors, ensuring a steady supply of blood for those in need and making a significant impact on saving lives."
        },
        {
            id: 5,
            icon: "fa fa-cutlery",
            header: "Food for All",
            description: "To provide food for the homeless, orphanages, and elderly, ensuring no one goes hungry."
        },
        {
            id: 6,
            icon: "fa fa-medkit",
            header: "Health Assistance",
            description: "Providing financial aid to children battling deadly diseases, bringing hope and support to their lives."
        },
    ]
    return <>
        <section className="mission" id="mission">
            <div className="container">
                <h2 className="mission__header font-bold">Our Mission</h2>
                <div className="flex mission-cards flex-wrap">
                {missionList.map(item => (
                    <div className="mission__item" key={item.id} >
                        <div className="mission__item__icon inline-block">
                            <span className={item.icon}></span>
                        </div>
                        <h4 className="mission__item__heading">{item.header}</h4>
                        <p className="mission__item__description">{item.description}</p>
                    </div>
                ))}
                </div>
            </div>
        </section>
    </>

}

export default Mission